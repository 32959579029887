@import '~antd/dist/antd.css';

body {
  background: #fafafa;
  font-family: 'Frutiger LT Std', sans-serif;
  margin: 0;
  padding: 0;
  height: auto;
}
iframe {
  display: none !important;
}

/***Style Changes on Arabic View Start**/
/***Item Page Start***/
body[dir='rtl'] .product-image {
  width: 50%;
  float: right;
}
body[dir='rtl'] .product-price {
  float: right;
  padding: 0 0rem 0 1rem;
}
body[dir='rtl'] .product-quantity {
  float: right;
}
body[dir='rtl'] .product-line-price {
  float: right;
}

/***Item Page END***/

body[dir='rtl'] .contact_input .form-control-input {
  padding: 0px 16px !important;
}

body[dir='rtl'] .title-addon-product {
  float: right;
}

/* body[dir='rtl'] .custom_grid_padding:nth-child(2n + 1) {
  padding: 0 15px 0 0 !important;
}
.custom_grid_padding:nth-child(2n + 1) {
  padding: 0 0 0 15px !important;
} */

.counter_code.rtl {
  visibility: hidden;
  opacity: 0;
}

body[dir='rtl'] .counter_code.rtl {
  visibility: visible;
  opacity: 1;
  border-right: 1px solid #e2dfdf;
  padding-right: 5px;
}

body[dir='rtl'] .counter_code.ltr {
  display: none;
}

body[dir='rtl'] .ReactFlagsSelect-module_label__27pw9,
body[dir='rtl'].ReactFlagsSelect-module_secondaryLabel__37t1D {
  padding-right: 10px;
  padding-left: 0;
}
/***Style Changes on Arabic View End**/
p {
  font-weight: 500;
  letter-spacing: 0.5px;
}
* {
  box-sizing: border-box;
}
#basic .ant-row {
  display: unset !important;
}
.ant-col.ant-form-item-label label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  margin: 4px 0px;
}
.ant-form-item-control-input-content input {
  border: 1px solid #c8c9c6;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
}
button.ant-btn.ant-btn-primary {
  width: 100%;
  background: #7665a0 !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 10px rgb(0 0 0 / 8%);
  border-radius: 2px;
  border-color: #7665a0 !important;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 0;
}
.ant-form-item-explain {
  margin: 4px 0 0;
}
.ant-modal-body {
  padding: 50px;
}
.resum-modal > label {
  font-weight: 400;
  font-size: 34px;
  color: #212121;
  margin: 0 0 20px 0;
  display: block;
}
.resum-modal {
  max-width: 350px;
  margin: auto;
}
.ant-modal-title {
  line-height: 22px;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 26px;
  text-align: center;
}
.ant-modal-header {
  padding: 76px 24px 16px;
  border-bottom: none !important;
}
.save-modal h3 {
  font-weight: 400;
  font-size: 33px;
  color: #212121;
  margin: 0 0 20px 0;
  display: block;
  line-height: normal;
  text-align: center;
}
.save-modal .title {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #212121;
}
.save-modal span.design-id {
  font-weight: 900;
}
.save-later-modal {
  text-align: center;
  color: #212121;
}
.save-later-modal h3 {
  font-weight: 400;
  font-size: 22px;
}
.save-later-modal button {
  box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 10px rgb(0 0 0 / 8%);
  color: #7665a0;
  border: 1px solid #7665a0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 44px;
  margin: 10px;
  text-transform: uppercase;
  font-size: 16px;
  max-width: 130px;
}
.save-later-modal button:hover {
  color: #fff;
  background-color: #7665a0;
  border-color: #7665a0;
}
.save-later-modal .title {
  margin: 20px 0 30px;
  font-size: 17px;
}
.color-code {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  padding: 6px;
  font-size: 0;
  margin-right: 8px;
}

.playerdropdown .ant-dropdown-menu-item,
.playerdropdown .ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
  color: #212121;
  padding: 10px 12px;
  font-size: 12px;
  line-height: normal;
}
.playerdropdown .ant-dropdown-menu-item:hover,
.playerdropdown .ant-dropdown-menu-submenu-title:hover {
  background-color: #ecedeb;
}
.playerdropdown.ant-dropdown-menu {
  min-width: 110px;
  background-color: #fafafa;
  padding: 0;
  box-shadow: none;
}
.playerdropdown .ant-dropdown-menu-title-content {
  flex: auto;
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-dropdown {
  border-radius: 0;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 10px 0 rgb(0 0 0 / 8%),
    0 9px 28px 0px rgb(0 0 0 / 5%);
  border: 1px solid #c8c9c6;
  padding: 0;
  margin: -16px 0 0 -1px;
}
.ant-select-item-option-selected {
  background: rgba(118, 101, 160, 0.2) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparent !important;
}
.ant-select-arrow::after {
  content: url(.././src/assets/dropdown_arrow.png);
}
.ant-select-item-option:hover {
  background-color: rgba(118, 101, 160, 0.2) !important;
}
.form-controls.header_dropdown span.ant-select-selection-item {
  padding-left: 15px !important;
}

/* .ant-select.form-controls.header_dropdown {
  border: none;
} */

/* .ant-select.form-controls.header_dropdown .ant-select-selector {
  border: 1px solid #c8c9c6 !important;
} */
@page {
  margin: 0;
}

/*****RTL CODE START*******/
body[dir='rtl'] .language {
  margin: 0 50px 0 0px;
}

body[dir='rtl'] .form-controls.header_dropdown span.ant-select-selection-item {
  padding-right: 41px !important;
}
body[dir='rtl'] .click-arrow {
  left: auto;
  right: 1px;
}
body[dir='rtl'] .demo-arrow-cls.back {
  transform: rotate(-45deg);
  left: 3px;
}
body[dir='rtl'] .rotate-arrow .demo-arrow-cls.back {
  transform: rotate(133deg);
  -webkit-transform: rotate(133deg);
  -ms-transform: rotate(133deg);
  left: -3px;
}
body[dir='rtl'] .storeid-outer .ant-select-selector {
  padding: 0 10px 0 30px !important;
}
body[dir='rtl'] .ant-select-arrow {
  left: 1.3rem;
  right: auto;
}
body[dir='rtl'] .product-grid-price {
  float: right;
  margin-left: 0.6rem;
}
body[dir='rtl'] .ar_price {
  float: right;
  margin-left: 0.6rem;
}
body[dir='rtl'] .combine_price {
  margin-left: 0.5rem;
  float: right;
}
body[dir='rtl'] .count-number {
  margin: 0 auto 0 0;
}
.combine_price_mobile {
  display: flex;
  display: -webkit-flex; /* Safari */
  display: -webkit-box;
  display: -ms-flexbox;
  flex-direction: row-reverse;
  -webkit-box-pack: flex-start !important;
  -ms-flex-pack: flex-start !important;
  justify-content: flex-start !important;
  align-items: center;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.combine_price_mobile span.ar_margin {
  margin: 0 5px 0 0 !important;
}
body[dir='rtl'] .combine_price_mobile {
  flex-direction: unset;
}

.combine_price_mobiles {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}
span.f_ar_price {
  margin: 0 0.3rem;
}

body[dir='rtl'] .without_sale_price.reverse span.f_ar_price {
  margin: 0 0rem;
}
body[dir='rtl'] span.f_ar_price {
  margin: 0 0.6rem;
}
body[dir='rtl'] .reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* body[dir='rtl'] .combine_price_mobiles_pdf {
  justify-content: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
}
.combine_price_mobiles_pdf {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
body[dir='rtl'] .combine_price_mobiles_pdf span.f_ar_price {
  order: 1;
} */

/*****RTL CODE END*******/

/* Scrollbar styling */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Works on Firefox */
/* .side-config {
  scrollbar-width: thin;
  scrollbar-color: #d8d8d8 #7665a0;
}

/* Works on Chrome, Edge, and Safari *
.side-config::-webkit-scrollbar {
  width: 4px;
}

.side-config::-webkit-scrollbar-track {
  background: #7665a0;
}

.side-config::-webkit-scrollbar-thumb {
  background-color: #7665a0;
} */

/* End scrollbar styling*/

@media (max-width: 1280.99px) {
  body[dir='rtl'] .toggle-mobile-menu {
    margin-right: auto;
    margin-left: 0;
  }
  body[dir='rtl'] .ant-select-arrow::after {
    left: auto;
  }
}

@media screen and (max-width: 992px) {
  .resum-modal > label {
    font-size: 24px;
    margin: 0 0 10px 0;
  }
  body {
    background: #fff;
  }
  .ant-modal-body {
    padding: 30px;
  }

  /*****RTL CODE START*******/

  /**MObile-Code Start**/
  body[dir='rtl'] .product-image {
    width: 100%;
  }
  body[dir='rtl'] .product-line-price {
    text-align: right;
  }
  body[dir='rtl'] .mobile-full-product-area .footer-price {
    float: right;
  }
  body[dir='rtl'] .product-quantity {
    text-align: left;
  }
  body[dir='rtl'] .bg-whitess {
    padding: 14px 14px 14px 10px !important;
  }
  body[dir='rtl'] .fix-width {
    text-align: right;
  }
  body[dir='rtl'] .overlay-images {
    text-align: right;
  }
  body[dir='rtl'] .header_dropdown .ant-select-arrow::after {
    left: -20px;
  }
  body[dir='rtl'] .store-inner .storeid .ant-select-selector {
    padding: 0 0px 0 30px !important;
  }
  body[dir='rtl'] .count-number {
    margin: 10px auto 0;
  }
  body[dir='rtl'] .combine_price {
    font-size: 12px;
  }
  /**MObile-Code Start**/

  /*****RTL CODE END*******/
}
